import React from 'react';
import Toast, { ToastProps } from './Toast';


export interface ToastContainerProps
{
    toasts: ToastProps[],
    position: ToastPosition
}

export enum ToastPosition
{
    TopCenter,
    BottomCenter,
    TopRight,
    BottomRight,
}

export default class ToastContainer extends React.Component<ToastContainerProps>
{

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.position === ToastPosition.TopCenter)
        { classes.push('top-center'); }
        if (this.props.position === ToastPosition.BottomCenter)
        { classes.push('bottom-center'); }
        if (this.props.position === ToastPosition.TopRight)
        { classes.push('top-right'); }
        if (this.props.position === ToastPosition.BottomRight)
        { classes.push('bottom-right'); }
        return classes.join(' ');
    }

    render(): React.ReactNode 
    {
        return (
            <div className={`toast-container ${this.getClasses()}`}>
                {
                    this.props.toasts.map((item: ToastProps, number: number) => (
                        <Toast key={number} {...item} />
                    ))
                }
            </div>
        );

    }
}