import { useNavigate } from 'react-router-dom'

import React, { useEffect } from 'react'

interface LogoutProps { }

export const Logout: React.FC<LogoutProps> = ({ }) => {
    const navigate = useNavigate()
    const hasReauthenticated = localStorage.getItem('RefreshAuthentication') !== null

    useEffect(() => {
        if (hasReauthenticated === true) {
            localStorage.removeItem('RefreshAuthentication')
            navigate('/') // for someone unknown reason this needs to be wrapped in a use effect
        }
    }, [hasReauthenticated])

    const containerStyles: React.CSSProperties = {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#414856',
        lineHeight: 1.5,
        fontFamily: 'Open Sans',
        fontSize: '.9rem',
        fontWeight: 100,
        textAlign: 'left',
        color: '#212529'
    };

    const boxStyles: React.CSSProperties = {
        border: '1px solid',
        borderRadius: '.6rem',
        boxShadow: '0px 2rem 4rem rgba(0, 0, 0, 0.25)',
        backgroundColor: '#E1E6EC',
        maxWidth: '50%',
        boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
        width: '37.5em',
        marginBottom: '1rem',
    };

    const divStyles: React.CSSProperties = {
        padding: '1.5rem',
        backgroundColor: 'white',
        borderRadius: '.6rem',
    };

    const bodyTextStyles: React.CSSProperties = {
        marginTop: '0',
        marginBottom: '1rem',
    };

    const endTextStyles: React.CSSProperties = {
        marginTop: '0',
        marginBottom: '0',
        padding: '1.5rem',
    };

    const linkStyles: React.CSSProperties = {
        color: '#E3124B',
        textDecoration: 'none',
    };

    return (
        <div style={containerStyles}>
            <div style={boxStyles}>
                <div style={divStyles}>
                    <h1>Logging out securely</h1>
                    <p style={bodyTextStyles}>
                        You are authenticated through <a href='http://ist.mit.edu/touchstone-detail' style={linkStyles} rel='noopener noreferrer' target='_blank'>MIT Touchstone</a>.<br /><br />
                        MIT Touchstone is a <a href='http://en.wikipedia.org/wiki/Single_sign-on' style={linkStyles} rel='noopener noreferrer' target='_blank'>single sign-on</a> service that allows members of the MIT community to log in to participating MIT websites. Once you are authenticated via Touchstone, <b>the only way to log out of Touchstone is to close the browser.</b>
                    </p>
                </div>
                <p style={endTextStyles}>
                    Please contact the Help Desk at <a href='mailto:computing-help@mit.edu' style={linkStyles}>computing-help@mit.edu</a> or 3-1101 for assistance.
                </p>
            </div>
        </div>
    );
}
