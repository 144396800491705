import React from 'react';
import { AuthProvider } from '../authorization';
import { Modal } from './Modal';
import { Button } from './HUIcomponents/Button';
import { EventBus } from '../common/EventBus';

interface SessionExpirationModalProps {
    authProvider: AuthProvider;
    customBody?: string;
    color?: string
}

export const SessionExpirationModal: React.FC<SessionExpirationModalProps> = ({
    customBody,
    color = '#1976d2'
}) => {
    const customBodyHTML = customBody ?? '<p>Your session has expired. The application will reload and any progress may be lost.</p>';

    function handleRedirect() {
        EventBus.dispatch('SESSION_EXPIRED', false)
        window.location.href = '/';
    }

    return (
        <Modal
            open={true}
            aria-labelledby="Session Expired Modal"
            aria-describedby="Session Expired Modal"
            header={
                <h2 id="modal-title" style={{ color: 'black', padding: '16px' }}>
                    Session has expired
                </h2>
            }
            footer={
                <div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                    <Button
                        text="Ok"
                        customBackgroundColor={color}
                        onClick={() => {
                            handleRedirect();                            
                        }}
                    />
                </div>
            }
        >
            <div style={{ padding: '24px' }}>
                <div
                    id="modal-description"
                    dangerouslySetInnerHTML={{ __html: customBodyHTML }}
                />
            </div>
        </Modal>
    );
};


