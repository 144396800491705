import * as React from 'react'
import { AtlasLogo } from '../AtlasLogo'
import Spinner from 'react-spinner-material';
import '../../css/main.style.css'

export interface HuiLoaderProps {
    isLoading?: boolean;
    title?: string;
    name: string;
    type: 'Default' | 'Outage' | 'No Access';
    backgroundColor?: string;
    contactEmail?: string;
    message?: string;
}

export const Loader: React.FC<HuiLoaderProps> = ({
    isLoading = false,
    title = 'ATLAS',
    name = 'Access Control Dashboard',
    type = 'Default',
    backgroundColor = '#1976d2',
    contactEmail = 'admin@mit.edu',
    message
}) => {

    const containerStyles: React.CSSProperties = {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1.5,
        fontFamily: 'Open Sans',
        backgroundColor: type !== 'Default' ? '#343a40' : backgroundColor,
        textAlign: 'center',
        color: '#fff',
    };

    const anchorStyles: React.CSSProperties = {
        color: '#E3124B',
        textDecoration: 'none'
    };

    const outageMessage = 'System is temporarily not available, please try again in 5 minutes.';
    const deniedMessage = `You do not have access to ${name} please contact `;

    const getContent = () => {
        if (isLoading) return null;
        if (message) return <p>{message}</p>;
        switch (type) {
            case 'Default':
                return null;
            case 'No Access':
                return <p>{deniedMessage}<a href={`mailto:${contactEmail}`} style={anchorStyles}>{contactEmail}</a></p>;
            case 'Outage':
                return <h4>{outageMessage}</h4>;
            default:
                return null;
        }
    };

    const logoStyles: React.CSSProperties = {
        textAlign: 'center',
        color: '#fff',
        maxWidth: '12rem',
        fontFamily: 'Arial',
        fontWeight: 200,
    };

    const nameStyles: React.CSSProperties = {
        textAlign: 'center',
        fontSize: '0.9rem',
        color: '#fff',
        fontFamily: 'Arial',
        marginTop: '1rem',
    };

    const circleStyles: React.CSSProperties = {
        display: 'inline-block'
    };

    return (
        <div style={containerStyles}>
            <div className='logo-container' style={logoStyles}>
                <div className='slide-in-blurred-top'>
                    <AtlasLogo title={title} sx={{ minWidth: '5rem', marginRight: 'auto', marginLeft: 'auto' }} />
                    <div className='fade-in' style={nameStyles}>{name}</div>
                    <br />
                    {isLoading && <span style={circleStyles} className="loader">
                        <Spinner size={50} color={"#80ffffff"} stroke={2} visible={isLoading} style={circleStyles} className="loader" />    
                    </span>}
                    {getContent()}
                </div>
            </div>
        </div>
    );
};