import React from 'react';
import { Icon } from './HUIcomponents/Icon';

export type ModalProps = {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    open: boolean;
    onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    children?: React.ReactNode;
};

export const Spacer: React.FC = () => <div style={{ flexGrow: 1 }} className='Hui-spacer' />;

export const Modal: React.FC<ModalProps> = ({ header, footer, open, onClose, children }) => {
    const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target === event.currentTarget) {
            onClose?.(event, 'backdropClick');
        }
    };

    const modalStyle: React.CSSProperties = {
        position: 'fixed',
        zIndex: 1300, // Standard z-index for modal from MUI
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: open ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Backdrop color
    };

    const contentStyle: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        backgroundColor: '#ffffff',
        borderRadius: '0.6rem',
        outline: 'none', // Remove default focus outline
    };

    const headerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 8px', // Equivalent to MUI dense Toolbar padding
    };

    const iconButtonStyle: React.CSSProperties = {
        marginRight: '8px', // Spacing adjustment
    };

    return (
        <div style={modalStyle} onClick={handleBackdropClick}>
            <div style={contentStyle}>
                <div style={headerStyle}>
                    {/* Use the provided header, or nothing if not provided */}
                    {header}
                    <Spacer />
                    {onClose && (
                        <button
                            aria-label="Close drawer"
                            id="modal-close"
                            onClick={(e) => onClose(e, 'escapeKeyDown')}
                            style={iconButtonStyle}
                        >
                            <Icon icon='times' type='fal' color='common.black' />
                        </button>
                    )}
                </div>
                <div id="modal-body">
                    {children}
                </div>
                {footer && <div id="modal-footer">{footer}</div>}
            </div>
        </div>
    );
};