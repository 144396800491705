import { AppInitializationDto } from '../types'

export class EventBus {
  private static _events: { [key: string]: any[] } = {}

  public static subscribe(event: string, callback: (data: any) => void) {
    if (!this._events[event]) this._events[event] = []
    this._events[event].push(callback)
  }

  public static unsubscribe(event: string) {
    delete this._events[event]
  }

  public static dispatch(event: string, data?: any) {
    if (!this._events[event]) return
    this._events[event].forEach((callback) => callback(data))
  }
}

export const appInitialized = (dto: AppInitializationDto) => {
  EventBus.dispatch('APP_INITIALIZED', dto)
}
