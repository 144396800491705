import React from 'react';

export interface ProgressBarProps
{
    //required
    percentage: number,
    ascending?: boolean
    //optional

}
 
export default class ProgressBar extends React.Component<ProgressBarProps>
{

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.percentage < 25)
            classes.push('red');
        else if (this.props.percentage >= 25 && this.props.percentage < 50)
            classes.push('orange')
        else if (this.props.percentage >= 50 && this.props.percentage < 75)
            classes.push('yellow')
        else if (this.props.percentage >= 75)
            classes.push('green')

        return classes.join(' ');
    }

    render(): React.ReactNode 
    {
        return (
            <div className={`progress ${this.getClasses()}`}>
            <div className="progress-bar" role="progressbar" style={{"width" : `${(this.props.percentage)}%`}}aria-valuenow={(this.props.percentage)} aria-valuemin={0} aria-valuemax={100}></div>
            </div>

        );
    }
}