import { EventBus } from '../common'
import { JsonMapType } from '../types'

export abstract class BaseApiController {
  private readonly fetchController: JsonMapType

  public constructor() {
    this.fetchController = {}
  }

  private async getHeaders(overrideHeaders: Record<string, string> = {}, file: boolean = false, impersonateKerb: string = ''): Promise<Headers> {
    const authorizationHeader = await this.getAuthorizationHeaders()

    let headers: { [key: string]: string } = {}

    if (!file) {
      headers['Content-Type'] = 'application/json'
      headers = { ...headers, ...authorizationHeader }
    }

    if (impersonateKerb !== '') {
      headers['X-Impersonated-User'] = impersonateKerb
    }

    return new Headers({ ...headers, ...overrideHeaders })
  }

  public async useFetch(
    method: string,
    fetchKey: string,
    url: string,
    body: JsonMapType | null = null,
    overrideHeaders: Record<string, string> = {},
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<JsonMapType> {
    if (this.fetchController[fetchKey] !== null && this.fetchController[fetchKey] !== undefined) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: JsonMapType = {
      method,
      headers: await this.getHeaders(overrideHeaders, file, impersonateKerb),
      signal
    }
    if (body !== null) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    this.fetchController[fetchKey] = null

    if (response.status === 503) {
      EventBus.dispatch('REGION_OUTAGE')
    }

    return response
  }

  abstract getAuthorizationHeaders(): Promise<Record<string, string>>
}
