import React, { FC, useMemo } from 'react'

import { library, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
library.add(far, fal, fas, fab, fat)

export type { IconName, IconPrefix }

export interface IconProps extends FontAwesomeIconProps {
    icon: IconName
    type?: IconPrefix
    fixedWidth?: boolean
    onClick?: () => any | undefined
    color?: any
    pulse?: any
    spin?: any
    className?: any
    border?: any
    inverse?: any
    flip?: any
    size?: any
    rotation?: any
    style?: any
    tabIndex?: any
}

const typeMap = [
    {
        type: 'far',
        long: 'regular'
    },
    {
        type: 'fal',
        long: 'light'
    },
    {
        type: 'fas',
        long: 'solid'
    },
    {
        type: 'fab',
        long: 'brand'
    },
    {
        type: 'fad',
        long: 'duotone'
    },
    {
        type: 'fat',
        long: 'thin'
    }
]

export const Icon: FC<IconProps> = ({
    icon = 'rectangle-portrait',
    type = 'far',
    color,
    pulse = false,
    spin = false,
    className = '',
    border = false,
    inverse = false,
    flip,
    size,
    rotation,
    style,
    tabIndex,
    fixedWidth,
    onClick
}) => {
    const styles = useMemo(() => {
        return { ...style, cursor: onClick != null && typeof onClick === 'function' ? 'pointer' : '' }
    }, [onClick, style])

    // Removed the usage of useTheme and getColor function,
    // and updated the color prop passed to the FontAwesomeIcon directly

    const getType = (): IconPrefix => [...typeMap].find(o => o.long === type || o.type === type)?.type as IconPrefix

    return (
        <FontAwesomeIcon
            icon={[getType(), icon]}
            color={color} // Pass the color prop directly without processing theme colors
            pulse={pulse}
            spin={spin}
            className={className}
            border={border}
            inverse={inverse}
            flip={flip}
            size={size}
            rotation={rotation}
            tabIndex={tabIndex}
            onClick={onClick} // Directly use onClick without wrapping into a function
            style={styles}
            fixedWidth={fixedWidth}
        />
    )
}