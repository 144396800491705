import React from 'react';
import Icon, { IconProps } from "./Icon";

export interface ToastProps
{
    icon: IconProps,
    title: string,
    subtext?: string,
    message: string,
    template?: any;
    show: boolean,
    onClose: any,
    footer?: any,
    type?: ToastType
}

export enum ToastType
{
    Default,
    Danger
}

export default class Toast extends React.Component<ToastProps>
{
    public static defaultProps =
        {
            type: ToastType.Default
        };

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.type === ToastType.Default)
        {
            classes.push('default')
        }

        if (this.props.type === ToastType.Danger)
        {
            classes.push('danger')
        }

        // I strongly doubt wether these IFs need to be here
        if (this.props.show)
            classes.push('show');
        else
            classes.push('hide');

        return classes.join(' ');
    }

    render(): React.ReactNode
    {
        if (this.props.template)
            return (
                <React.Fragment>
                    {this.props.template(this.props)}
                </React.Fragment>
            );

        return (
            <div className={`toast ${this.getClasses()}`} role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <Icon {...this.props.icon} />&nbsp;<strong className="mr-auto"> {this.props.title}</strong>
                    <small>{this.props.subtext}</small>
                    <button onClick={() => this.props.onClose()} type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <i className={`far fa-times`}></i>
                    </button>
                </div>
                <div className="toast-body">
                    <div dangerouslySetInnerHTML={{ __html: this.props.message }}/>
                    {this.props.template}
                </div>
                <div className="toast-footer">
                    {this.props.footer}
                </div>
            </div>

        );
    }
}