import React, { Suspense, useEffect, useState } from 'react'
import { SessionExpirationModal } from './SessionExpirationModal'
import { EventBus } from '../common'
import { AppConfigData, AppInitializationDto } from '../types'
import { AuthProvider } from '../authorization'
import { HuiLoaderProps, Loader } from './HUIcomponents/Loader'
import '../css/main.style.css'

interface Props {
  App: React.LazyExoticComponent<React.FC<{}>>
  appInitializer: () => void
  appConfigData: AppConfigData
  loaderProps: HuiLoaderProps
}
// eslint-disable-line @typescript-eslint/no-unused-vars
const InitializationImplementation: React.FC<Props> = ({ App, appInitializer, appConfigData, loaderProps }) => {
  const [initializeDto, setinitializeDto] = useState<AppInitializationDto>({
    isLoading: true,
    hasAccess: true,
    hasError: false
  })
  const [sessionExpired, setSessionExpired] = useState<boolean>(false)
  // const [sessionExpiredPrompted, setSessionExpiredPrompted] = useState<boolean>(false)
  // const [showApp, setShowApp] = useState<boolean>(false)

  const authProvider = new AuthProvider(appConfigData)

  // Set Up Listeners For Global Events
  useEffect(() => {

    EventBus.subscribe('APP_INITIALIZED', (dto: AppInitializationDto) => {
      setinitializeDto(dto)
    })
    // EventBus.subscribe('SESSION_EXPIRED_PROMPTED', () => setSessionExpiredPrompted(true))
    EventBus.subscribe('SESSION_EXPIRED', (expired: boolean) => setSessionExpired(expired))
    EventBus.subscribe('AUTH_INITIALIZED', () => console.log('AUTH_INITIALIZED'))
    EventBus.subscribe('AUTH_CONFIGURED', () => setSessionExpired(false))

    return () => {
      EventBus.unsubscribe('APP_INITIALIZED')
      EventBus.unsubscribe('SESSION_EXPIRED')
      EventBus.unsubscribe('AUTH_CONFIGURED')
    }
  }, [])

  const init = async () => {
    
    await initializeAuthProvider()
      .then(() => {
        // if(sessionExpired){
          appInitializer()
        // }
      })
      .catch(error => {
        console.error('AWS-REACT:: auth provider initialized error', error)
        setinitializeDto({
          isLoading: false,
          hasAccess: false,
          hasError: true
        })
      })
  }

  useEffect(() => {
    init()
  }, [])

  const initializeAuthProvider = async (): Promise<void> => {
    // const authProvider = new AuthProvider(appConfigData)
    await authProvider.configure()
    if (!sessionExpired){
      await authProvider.getToken(true)
    }
  }

  const getLoaderType = (): 'Default' | 'No Access' => {
    if (initializeDto.hasError) return 'No Access'
    if (initializeDto.hasAccess || initializeDto.isLoading) return 'Default'
    return 'No Access'
  }

  if (initializeDto.isLoading || !initializeDto.hasAccess || initializeDto.hasError) {
    return <Loader {...loaderProps} isLoading={initializeDto.isLoading} type={getLoaderType()} />
  }

  return (
    <>
      {sessionExpired && <SessionExpirationModal authProvider={authProvider} color={loaderProps.backgroundColor} />}
      <Suspense fallback={<Loader {...loaderProps} isLoading={initializeDto.isLoading} type={getLoaderType()} />}>
        <App />
      </Suspense>
    </>
  )
}

export const Initialization = InitializationImplementation
