/* eslint-disable @typescript-eslint/no-extraneous-class */
import { EventBus, getConfigFromFile, getRedirectUri, timeout } from '../common'
import { LibraryEnum } from '../types/Library'
//Amplify 
import { FederatedSignInOptionsCustom } from '@aws-amplify/auth/lib/types'
import { Amplify, Auth } from 'aws-amplify'
//OKTA
import { OktaAuth, isAccessToken } from '@okta/okta-auth-js'
import { AppConfig, AppConfigData } from '../types'

export class AuthProvider {
  public appConfig: AppConfig
  private oktaAuth: OktaAuth
  private localStorageLibraryConst: string = "Library";
  // private appConfigData: AppConfigData

  constructor(appConfigData: AppConfigData) {
    // this.appConfigData = appConfigData
    this.appConfig = getConfigFromFile(appConfigData)
    this.oktaAuth = new OktaAuth(getConfigFromFile(appConfigData).okta)
  }

  public configure = async (): Promise<void> => {

    const shouldRefreshToken = this.getShouldRefreshToken();
    localStorage.setItem('isGlobalEnabled', "false");
    await this.getValidLibrary();

    const localStorageLibrary = localStorage.getItem(this.localStorageLibraryConst);

    //Library will return a value, reason for optional void is that signOut()
    //will be called in which case the function will be interrupted anyway
    // Also checking for null and undefined here, then defaulting to cognito
    if (localStorageLibrary === LibraryEnum.cognito.valueOf() ||
      (localStorageLibrary === null || localStorageLibrary === undefined)) {

      const config: AppConfig = this.appConfig
      await Amplify.configure(config.amplify)

      // only refresh token if there is a user logged in
      if (shouldRefreshToken) {
        localStorage.removeItem('TokenExpiration')
        localStorage.removeItem('TokenValidity')
        await this.signIn()
      }
      // }

    } else if (localStorageLibrary === LibraryEnum.okta.valueOf()) {
      const authenticated = await this.oktaAuth.isAuthenticated()
      // only refresh token if there is a user logged in
      if (authenticated) {
        if (shouldRefreshToken) {

          localStorage.removeItem('TokenExpiration')
          localStorage.removeItem('TokenValidity')
          localStorage.removeItem('OKTATokenIssuedAt')

        }
      }

      // Handle callback - this needs to run first, before anything else. Required for SPAs
      if (this.oktaAuth.token.isLoginRedirect()) {
        const { tokens } = await this.oktaAuth.token.parseFromUrl()
        this.oktaAuth.tokenManager.setTokens(tokens)
      }
      this.oktaAuth.start() // will update auth state and call event listeners

    } else {
      console.error("Error during configure:", "Unknown Auth Library found")
    }
  }

  public getToken = async (initialize: boolean = false): Promise<string> => {
    try {

      const shouldRefreshToken = this.getShouldRefreshToken();

      const library = localStorage.getItem(this.localStorageLibraryConst);
      // Library will return a value, reason for optional void is that signOut()
      // will be called in which case the `function will be interrupted anyway
      // Also checking for null and undefined here, then defaulting to cognito
      if (library! === LibraryEnum.cognito.valueOf() ||
        (library === null || library === undefined)) {

        // check if token has expired
        const response = await Auth.currentAuthenticatedUser()
        if (response) {
          if (shouldRefreshToken) {
            EventBus.dispatch('SESSION_EXPIRED', true)
            return ''
          }
        }
        const session = response.getSignInUserSession()
        const tokenValidity = (session.getAccessToken().getExpiration() - session.getAccessToken().getIssuedAt()) * 1000
        const expirationInMs = session.getAccessToken().getExpiration() * 1000

        localStorage.setItem('TokenExpiration', `${expirationInMs}`)
        localStorage.setItem('TokenValidity', `${tokenValidity}`)
        return session.getAccessToken().getJwtToken()

      } else if (library! === LibraryEnum.okta.valueOf()) {
        const response = await this.oktaAuth.tokenManager.get('accessToken')
        // check if token has expired
        if (response && isAccessToken(response)) {
          if (shouldRefreshToken) {
            EventBus.dispatch('SESSION_EXPIRED', true)
            return ''
          }

          var issuedAt = localStorage.getItem('OKTATokenIssuedAt') ?? '0'
          if (issuedAt === '0') {
            issuedAt = `${new Date().getTime()}`
            localStorage.setItem('OKTATokenIssuedAt', `${issuedAt}`)
          }

          const expirationInMs = response.expiresAt * 1000
          const tokenValidity = expirationInMs - parseInt(issuedAt)// * 1000
          localStorage.setItem('TokenExpiration', `${expirationInMs}`)
          localStorage.setItem('TokenValidity', `${tokenValidity}`)
          return response.accessToken
        } else {
          throw new Error('Invalid access token')
        }
      } else {
        throw new Error('Unknown Auth Library found')
      }
    } catch (error) {
      if (initialize) {
        await this.signIn()
      } else {
        EventBus.dispatch('SESSION_EXPIRED', true)
      }
      return ''
    }
  }

  public getValidLibrary = async (): Promise<LibraryEnum | void> => {
    let activeLibrary = await this.getLibrary();
    localStorage.setItem(this.localStorageLibraryConst, activeLibrary != null ? activeLibrary!.valueOf() : "");
    return activeLibrary;
  }

  public getLibrary = async (): Promise<LibraryEnum | void> => {

    try {
      //TODO: 1) Use a var for the url 2) get project name as props 3) clean up this is super messy atm
      const stage = this.appConfig.stage
      const suffix = stage === 'prod' ? '' : `-${stage}`

      const res = await fetch(`https://get.atlas-config${suffix}.mit.edu/?key=${this.appConfig.name}`);
      let body = await res.json();

      if (res.status == 200) {
        let authLibrary: LibraryEnum = LibraryEnum.cognito;
        if (body instanceof Object) {
          for (var key in body) {
            if (body.hasOwnProperty(key)) {
              if (body[key] === "true") {
                switch (key as string) {
                  case LibraryEnum.globalEnable.valueOf():
                    // set localstorage for global enable only
                    localStorage.setItem('isGlobalEnabled', "true");
                    break;
                  case LibraryEnum.cognito.valueOf():
                    authLibrary = LibraryEnum.cognito
                    break;
                  case LibraryEnum.okta.valueOf():
                    authLibrary = LibraryEnum.okta
                    break;
                  case LibraryEnum.cirrus.valueOf():
                    authLibrary = LibraryEnum.cirrus
                    break;
                  default:
                    authLibrary = LibraryEnum.cognito
                }
              }
            }
          };
          return authLibrary;
        }
      } else {
        return LibraryEnum.cognito;
      }
    } catch (error) {
      console.error('getLibrary', error);
    }

    return LibraryEnum.cognito;
  }

  public signIn = async (): Promise<void> => {

    const library = localStorage.getItem(this.localStorageLibraryConst);
    // Also checking for null and undefined here, then defaulting to cognito
    if (library! === LibraryEnum.cognito.valueOf() ||
      (library === null || library === undefined)) {
      const options: FederatedSignInOptionsCustom = {
        customProvider: 'Touchstone',
        customState: this.getCognitoCustomState()
      }
      await Auth.federatedSignIn(options)
      await timeout(50000) // Amplify promise returns before the redirect happens. Need to hold here until redirect happens otherwise app's first screen renders
    } else if (library! === LibraryEnum.okta.valueOf()) {
      try {
        // Check if the user is already authenticated
        const isAuthenticated = await this.oktaAuth.isAuthenticated()
        if (!isAuthenticated) {
          // Initiate the sign-in process
          this.oktaAuth.signInWithRedirect()
        }
      } catch (error) {
        console.error("Error during sign-in:", error)
      }
    } else {
      console.error("Error during sign-in:", "Unknown Auth Library found")
    }
  }

  public signOut = async (libraryEnumValue: string | void): Promise<void> => {

    const { logoutRedirect } = getRedirectUri();

    const localLibrary = localStorage.getItem(this.localStorageLibraryConst);
    const library = libraryEnumValue !== null && libraryEnumValue !== undefined ? libraryEnumValue : localLibrary;

    localStorage.removeItem('TokenExpiration')
    localStorage.removeItem('TokenValidity')
    localStorage.removeItem('OKTATokenIssuedAt')
    localStorage.removeItem(this.localStorageLibraryConst)

    // Also checking for null and undefined here, then defaulting to cognito
    if (library === LibraryEnum.cognito.valueOf() ||
      (library === null || library === undefined)) {
      await Auth.signOut()
    } else if (library === LibraryEnum.okta.valueOf()) {
      await this.oktaAuth.signOut({ postLogoutRedirectUri: logoutRedirect })
      this.oktaAuth.start()
    } else {
      console.error("Error during sign-out:", "Unknown Auth Library found")
    }
  }

  // public refreshToken = async (): Promise<void> => {
  //   localStorage.setItem('RefreshAuthentication', 'true')
  //   await this.signOut()
  // }

  public getShouldRefreshToken = (): boolean => {

    const tokenExpiration = parseInt(localStorage.getItem('TokenExpiration') ?? '0')
    // const tokenValidity = parseInt(localStorage.getItem('TokenValidity') ?? '0')
    const currentTime = new Date().getTime()

    if (tokenExpiration === 0) {
      return false
    }

    // not sure if this is needed below, it used to be currentTime >= tokenExpiration - (tokenValidity / 2) but I changed it to currentTime >= tokenExpiration
    // return currentTime >= tokenExpiration - (tokenValidity / 2)
    return currentTime >= tokenExpiration

  }

  private getCognitoCustomState(): string | undefined {
    const customState = localStorage.getItem('CognitoCustomState') ?? undefined
    return customState
  }
}